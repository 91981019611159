import { object, string } from 'yup';
import { WorkOrder } from '../../../types/workOrder';
import { pkValidation } from '../../../utils/pkValidation';

export const newWorkOrderSchema = object<WorkOrder>({
  operator: string().required(),
  registerInitDate: string(),
  actionActivity: string().when('$isAction', ([isAction], schema) =>
    isAction ? schema.required() : schema.notRequired()
  ),
  actionElementOrder: string().when('$isAction', ([isAction], schema) =>
    isAction ? schema.required() : schema.notRequired()
  ),
  predefine: string(),
  capitol: string().required(),
  subCapitol: string().required(),
  classification: string().when(
    '$isActionOrIsAccident',
    ([isActionOrIsAccident], schema) =>
      isActionOrIsAccident ? schema.notRequired() : schema.required()
  ),
  concession: string().required(),
  city: string().required(),
  pkInitRoad: string().required(),
  pkEndRoad: string().required(),
  road: string().required(),
  pkInit: string()
    .matches(/^\d+\s?\+\s?\d{3,4}$/)
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  pkEnd: string()
    .matches(/^\d+\s?\+\s?\d{3,4}$/)
    .test(function (value) {
      const regex = new RegExp(/^\d+\s?\+\s?\d{3,4}$/);
      return regex.test(value || '') && pkValidation(value || '', this.parent);
    })
    .required(),
  track: string().required(),
  direction: string().when('$isAction', ([isAction], schema) =>
    isAction ? schema.required() : schema.notRequired()
  ),
  margin: string().when('$isAction', ([isAction], schema) =>
    isAction ? schema.required() : schema.notRequired()
  ),
  lane: string().required(),
  campaign: string().when('$switchState', ([switchState], schema) =>
    switchState ? schema.required() : schema.notRequired()
  ),
  isCampaignActive: string(),
  description: string().required(),
});
