import { redirect } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../../gql/client';
import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
} from '../../../../../../gql/mutations/orders';
import {
  CREATE_NEW_IMAGE,
  DELETE_MANY_IMAGES,
} from '../../../../../../gql/mutations/accidents';
import { NEW_IMAGE } from '../../../../../../gql/fragments/image';
import {
  CONNECT_MANY_USERS_TO_ACTION,
  DELETE_MANY_VEHICLE_OCCUPANTS_ACTION,
  DISCONNECT_MANY_USERS_FROM_ACTION,
  UPDATE_ACTION,
  UPDATE_ACTION_RELATIONSHIPS,
} from '../../../../../../gql/mutations/actions';
import { UPDATE_POSITION_ACTION } from '../../../../../../gql/mutations/geoPositionActions';
import { NEW_VEHICLE_OCCUPANT_ACTION } from '../../../../../../gql/mutations/vehicles';
import {
  CREATE_NEW_MATERIAL_UNIT,
  DELETE_MANY_MATERIALS,
  UPDATE_MANY_MATERIALS_UNITS,
} from '../../../../../../gql/mutations/materialUnits';
import {
  CREATE_NEW_ANIMAL_UNIT,
  DELETE_MANY_ANIMAL_UNITS,
  UPDATE_MANY_ANIMAL_UNITS,
} from '../../../../../../gql/mutations/animalUnits';
import {
  CREATE_NEW_AUX_MACHINE_UNIT,
  DELETE_MANY_AUX_MACHINE_UNITS,
  UPDATE_MANY_AUX_MACHINE_UNITS,
} from '../../../../../../gql/mutations/auxMachineUnits';
import { NEW_ANIMAL_UNIT } from '../../../../../../gql/fragments/animalUnit';
import { NEW_AUX_MACHINE_UNIT } from '../../../../../../gql/fragments/auxMachineUnit';
import { NEW_MATERIAL_UNIT } from '../../../../../../gql/fragments/materialUnit';
import {
  GET_VEHICLE_BY_ID,
  GET_VEHICLE_OCCUPANT_BY_ID,
} from '../../../../../../gql/queries/vehicles';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import { GET_ACTION_BY_ID } from '../../../../../../gql/queries/actions/actions';
import { GET_USER_BY_ID } from '../../../../../../gql/queries/users';
import { GET_ASSET_BY_ID } from '../../../../../../gql/queries/actions/assets';
import { GET_ACTIVITY_BY_ID } from '../../../../../../gql/queries/actions/activitys';
import { Reference, StoreObject } from '@apollo/client';
import {
  CREATE_EXTERNAL_WORK,
  DELETE_EXTERNAL_WORKS_BY_IDS,
  UPDATE_EXTERNAL_WORKS,
} from '../../../../../../gql/mutations/externalWork';
import { GET_EXTERNAL_WORK_BY_ID } from '../../../../../../gql/queries/actions/externalWorks';
import { GET_ANIMAL_UNIT_BY_ID } from '../../../../../../gql/queries/actions/animals';
import { CREATE_EXTERNAL_COMPANY } from '../../../../../../gql/mutations/externalCompanies';
import { FETCH_EXTERNAL_COMPANIES } from '../../../../../../gql/queries/actions/externalCompanies';

// Constants
import {
  formActionNaming,
  formActionValidationNaming,
} from '../../../../../../components/forms/ActionForms/constants';

// Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { UploadImage } from '../../../../../../utils/image';
import { deleteWholeDirectory } from '../../../../../../utils/files';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';
import { externalCompaniesNaming } from '../../../../../../components/display/tables/formTables/ExternalCompaniesSelectionTable/constants';
import {
  findNewAndUpdatedExternalCompanies,
  findNewIDs,
  findRemovedExternalCompaniesIds,
  findNewAndUpdatedAnimals,
  findRemovedAnimalIds,
  findRemovedIDs,
} from './utils';

// Types
import { OrderState, OrderType } from '../../../../../../types/orders';
import { DataState } from '../../../../../../components/forms/ActionForms/types';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

// Redux
import { store } from '../../../../../../state/configureStore';
import { actionTabsReset } from '../../../../../../state/actions/ui/actionTabs';
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { getCursor } from '../../../../../../state/selectors/ui/tables';

export const saveData = async (formData: FormData) => {
  // order data
  const orderActionData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(
        formActionNaming.NEW_VALIDATION_ACTION_DATA
      ) as string
    )
  );

  const externalWorksValidation = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(
        formActionValidationNaming.NEW_VALIDATION_OPERATORS_AND_VEHICLE_FORM
      ) as string
    )
  );

  // Action data
  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: orderActionData.id,
    },
  });

  // Redux data
  const dispatch = store.dispatch;
  const cursorMultimedia = getCursor(tableIds.MULTIMEDIA)(store.getState());

  // Location data
  const pkInit = formData.get('actionPkInit');
  const pkInitRaw =
    pkInit && typeof pkInit === 'string' && pkInit.replace(/ /g, '').split('+');
  const pkEnd = formData.get('actionPkEnd');
  const pkEndRaw =
    pkEnd && typeof pkEnd === 'string' && pkEnd.replace(/ /g, '').split('+');

  const concessionId = formData.get('actionConcession');
  const track = formData.get('actionTrack');
  const roadId = formData.get('actionRoad');
  const lane = formData.get('actionLane');
  const direction = formData.get('actionDirection');
  const margin = formData.get('actionMargin');

  // Edition data
  const editionActivity = formData.get('editionActivity');
  const editionElementOrder = formData.get('editionElementOrder');
  const editionCapitol = formData.get('editionCapitol');
  const editionSubCapitol = formData.get('editionSubCapitol');
  const editionAsset = formData.get('editionAsset');
  const editionAssetSwitch = formData.get('editionAssetSwitch');
  const editionDescription = formData.get('editionDescription');
  const editionLaneCutting = formData.get('editionLaneCutting');

  // Hours data
  const registerInitDate = formData.get('registerInitDate');
  const registerEndDate = formData.get('registerEndDate');
  const hoursType = formData.get('hoursType');
  const totalHours = formData.get('totalHours') as string;
  const splitHourAndMinutes = totalHours?.split(':');
  const minutesTotals = splitHourAndMinutes
    ? parseInt(splitHourAndMinutes[0]) * 60 + parseInt(splitHourAndMinutes[1])
    : undefined;
  const isExternal = JSON.parse(formData.get('isExternal') as string);
  const tablesDataRaw = sessionStorage.getItem(
    formActionValidationNaming.DATA_CHANGES
  );

  // Tables data
  const tablesData = tablesDataRaw
    ? JSON.parse(decompressSessionStorage(tablesDataRaw))
    : {};

  // External companies data
  const currentExternalWorks = await Promise.all(
    action.externalWorksIDs.map(async (externalWorkId: string) => {
      const {
        data: { externalWork },
      } = await client.query({
        query: GET_EXTERNAL_WORK_BY_ID,
        variables: {
          id: externalWorkId,
        },
      });

      return externalWork;
    })
  );

  const modificationExternalCompanies = externalWorksValidation?.externals?.map(
    (external: { externalCompanyIDs: string[]; id: string }) => ({
      ...external,
      externalCompanyIDs: [external?.externalCompanyIDs?.[0] ?? external.id],
    })
  );

  const { worksToAdd, worksToUpdate } = findNewAndUpdatedExternalCompanies(
    currentExternalWorks,
    modificationExternalCompanies
  );

  const worksToDelete = findRemovedExternalCompaniesIds(
    currentExternalWorks,
    modificationExternalCompanies
  );

  // Animals table data
  const animalsTableDataRaw = sessionStorage.getItem(
    formActionValidationNaming.NEW_VALIDATION_ANIMALS_FORM
  );

  const animalsTable = animalsTableDataRaw
    ? Object.values(
        JSON.parse(decompressSessionStorage(animalsTableDataRaw))
          ?.animalsTable || {}
      )
    : [];

  const currentAnimals = await Promise.all(
    action.animalUnitIDs.map(async (id: string) => {
      const {
        data: { animalUnit },
      } = await client.query({
        query: GET_ANIMAL_UNIT_BY_ID,
        variables: {
          id,
        },
      });

      return {
        animalUnitId: animalUnit.id,
        id: animalUnit.animalIDs[0],
        statusId: animalUnit.animalStatusIDs[0],
        ut: animalUnit.unit,
      };
    })
  );

  const { animalsToUpdate, animalsToAdd } = findNewAndUpdatedAnimals(
    currentAnimals,
    animalsTable as any
  );
  const animalsToDelete = findRemovedAnimalIds(
    currentAnimals,
    animalsTable as any
  );

  // Images
  const imagesToAdd =
    tablesData?.editionData && tablesData.editionData?.[DataState.added];
  const imagesToDelete =
    tablesData?.editionData && tablesData.editionData?.[DataState.deleted];

  // Operators data

  const currentOperatorsIDs = action.userIDs;

  const newOperatorsIDs = externalWorksValidation.internalOperatorsTable
    .filter((operator: null | { id: string }) => operator)
    .filter((operator: { id: string }) => operator.id)
    .map((item: { id: string }) => item.id);
  const newIDs = findNewIDs(currentOperatorsIDs, newOperatorsIDs);
  const idsToRemove = findRemovedIDs(currentOperatorsIDs, newOperatorsIDs);

  const addInternalOperators = newIDs.length && newIDs;

  const operatorsToAddIDs = [...(addInternalOperators || [])];

  const deleteInternalOperators = idsToRemove.length && idsToRemove;

  const operatorsToDeleteIDs = [...(deleteInternalOperators || [])];

  // VehiclesData
  const newVehiclesData = (
    Object.values(externalWorksValidation.vehicleTable) as any
  )
    .filter(
      (vehicle: ArrayLike<unknown> | { [s: string]: unknown }) =>
        Object.entries(vehicle).length
    )
    .map(
      (vehicle: {
        driverID: string;
        driver: string;
        vehicleID: string;
        id: string;
        vehicleOccupantID: string;
      }) => ({
        driverId: vehicle.driverID || vehicle.driver,
        vehicleId: vehicle.vehicleID || vehicle.id,
        vehicleOccupantId: vehicle.vehicleOccupantID,
      })
    )
    .filter((vehicle: { driverId: string }) => vehicle.driverId);

  const vehiclesToDeleteIDs = action.vehicleOccupantIDs;

  // Materials data
  const addMaterialsChangesItems = tablesData?.materialsData &&
    tablesData.materialsData?.[DataState.added] && [
      ...tablesData.materialsData[DataState.added],
    ];
  const updateMaterialsChangesItems = tablesData?.materialsData &&
    tablesData.materialsData?.[DataState.updated] && [
      ...tablesData.materialsData[DataState.updated],
    ];

  const updateMaterialsChangesItemsFiltered = updateMaterialsChangesItems?.map(
    (materialUpdated: { unitId: string; newValue: number }) => {
      return {
        materialUnitId: materialUpdated.unitId,
        newValue: materialUpdated.newValue,
      };
    }
  );

  const deleteMaterialsChangesIds = tablesData?.materialsData &&
    tablesData.materialsData?.[DataState.deleted] && [
      ...tablesData.materialsData[DataState.deleted],
    ];

  // Machinery data
  const addMachineryChangesItems = tablesData?.machineryData &&
    tablesData.machineryData?.[DataState.added] && [
      ...tablesData.machineryData[DataState.added],
    ];
  const updateMachineryChangesItems = tablesData?.machineryData &&
    tablesData.machineryData?.[DataState.updated] && [
      ...tablesData.machineryData[DataState.updated],
    ];

  const updateMachineryChangesItemsFiltered = updateMachineryChangesItems?.map(
    (machinaryUpdated: { unitId: string; newValue: number }) => {
      return {
        auxMachineUnitId: machinaryUpdated.unitId,
        newValue: machinaryUpdated.newValue,
      };
    }
  );

  const deleteMachineryChangesIds = tablesData?.machineryData &&
    tablesData.machineryData?.[DataState.deleted] && [
      ...tablesData.machineryData[DataState.deleted],
    ];

  try {
    if (orderActionData.id) {
      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: action.orderIDs[0],
        },
      });

      const {
        data: { order: parentOrder },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: order.parentExpeditionOrderIDs[0],
        },
      });
      if (!isExternal && action.externalWorksIDs.length > 0) {
        await client.mutate({
          mutation: DELETE_EXTERNAL_WORKS_BY_IDS,
          variables: {
            ids: action.externalWorksIDs,
          },
        });
      }
      if (isExternal && action.vehicleOccupantIDs.length > 0) {
        await client.mutate({
          mutation: UPDATE_ACTION_RELATIONSHIPS,
          variables: {
            input: {
              id: orderActionData.id,
              userRelationships: [],
            },
          },
        });
        await client.mutate({
          mutation: DELETE_MANY_VEHICLE_OCCUPANTS_ACTION,
          variables: {
            ids: action.vehicleOccupantIDs,
          },
        });
      }

      if (worksToUpdate.length > 0) {
        worksToUpdate.forEach(async (externalWorkToUpdate) => {
          await client.mutate({
            mutation: UPDATE_EXTERNAL_WORKS,
            variables: {
              input: externalWorkToUpdate,
            },
          });
        });
      }

      const imageArray = (imagesToAdd || []).length
        ? await UploadImage(OrderType.Action)
        : [];

      const imageArrayWithDeletes = imageArray.map(async (image) =>
        client.mutate({
          mutation: CREATE_NEW_IMAGE,
          variables: {
            input: {
              name: image.name,
              creatingDate: image.creatingDate,
              cloudinaryPublicId: image.cloudinaryPublicId,
              orderId: orderActionData.orderId,
            },
          },
          update(cache, { data: { createImage } }) {
            cache.modify({
              fields: {
                images(existingImages = []) {
                  const newImageRef = cache.writeFragment({
                    data: createImage,
                    fragment: NEW_IMAGE,
                  });
                  return [...existingImages, newImageRef];
                },
              },
            });
          },
        })
      );

      const deletedImages = imagesToDelete?.length;
      const addedImages = imagesToAdd?.length;

      client.cache.modify({
        fields: {
          orders(existingOrders = {}, { readField }) {
            const newOrderRef = {
              __ref: `Order:${orderActionData.orderId}`,
            };

            const images: readonly string[] | undefined = readField(
              'imageIDs',
              newOrderRef
            );

            if (images?.length === 0 && addedImages > 0) {
              const order = client.readQuery({
                query: GET_ORDER_BY_ID,
                variables: {
                  id: orderActionData.orderId,
                },
              });

              let mostCloseOrder: undefined | string;

              const dateOrder = new Date(order.order.creationDate);

              for (let i = 0; i < existingOrders?.orders?.length - 1; i++) {
                const idOrder = existingOrders?.orders[i]?.__ref.split(':')[1];

                const currentOrder = client.readQuery({
                  query: GET_ORDER_BY_ID,
                  variables: {
                    id: idOrder,
                  },
                });
                const currentOrderDate = new Date(
                  currentOrder?.order?.creationDate
                );

                if (dateOrder.getTime() > currentOrderDate.getTime()) {
                  mostCloseOrder =
                    existingOrders?.orders[i]?.__ref.split(':')[1];
                  break;
                }
              }

              if (!mostCloseOrder) {
                return {
                  ...existingOrders,
                };
              }

              const mostCloseOrderIndex = existingOrders.orders.findIndex(
                (item: Reference | StoreObject) =>
                  readField('id', item) === mostCloseOrder
              );
              const truncatedArray = truncateArrayAfterNull(
                existingOrders.orders
              );

              truncatedArray.splice(mostCloseOrderIndex, 0, newOrderRef);

              const newOrdersArray = truncatedArray;

              newOrdersArray.splice(-2, 1);

              const setCursorMultimedia = {
                ...cursorMultimedia,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              if (existingOrders?.orders?.length > 0) {
                dispatch(setCursor(tableIds.MULTIMEDIA, setCursorMultimedia));
              }
              return {
                ...existingOrders,
                orders: newOrdersArray,
                totalCount: existingOrders.totalCount + 1,
                pageInfo: {},
              };
            } else {
              return {
                ...existingOrders,
              };
            }
          },
        },
      });

      worksToDelete?.length &&
        (await client.mutate({
          mutation: DELETE_EXTERNAL_WORKS_BY_IDS,
          variables: {
            ids: worksToDelete,
          },
        }));

      if (worksToAdd?.length && isExternal) {
        const companiesToCreate = worksToAdd.filter(
          (work) => work.externalCompanyIDs[0] === 'new_company'
        );

        const normalWorks = worksToAdd.filter(
          (work) => work.externalCompanyIDs[0] !== 'new_company'
        );

        companiesToCreate.length > 0 &&
          (await Promise.all(
            companiesToCreate.map(async (company) => {
              const {
                data: { createOneExternalCompany },
              } = await client.mutate({
                mutation: CREATE_EXTERNAL_COMPANY,
                variables: {
                  companyName: company.company,
                },
              });

              return client.mutate({
                mutation: CREATE_EXTERNAL_WORK,
                variables: {
                  input: {
                    operatorsNumber: Number(company.operatorsNumber),
                    externalCompanyId: createOneExternalCompany.id,
                    actionId: orderActionData.id,
                  },
                },
              });
            })
          ));

        await Promise.all(
          normalWorks.map(async (work) =>
            client.mutate({
              mutation: CREATE_EXTERNAL_WORK,
              variables: {
                input: {
                  operatorsNumber: Number(work.operatorsNumber),
                  externalCompanyId: work.id,
                  actionId: orderActionData.id,
                },
              },
            })
          )
        );
      }

      await client.query({
        query: FETCH_EXTERNAL_COMPANIES,
        fetchPolicy: 'network-only',
      });

      imagesToDelete?.length
        ? imageArrayWithDeletes.push(
            client.mutate({
              mutation: DELETE_MANY_IMAGES,
              variables: {
                ids: imagesToDelete,
              },
              update(cache, { data: { deleteManyImagesByIds } }) {
                deleteManyImagesByIds.forEach(
                  (image: { id: Reference | StoreObject }) => {
                    cache.modify({
                      id: cache.identify(image.id),
                      fields: {
                        images(_, { DELETE }) {
                          return DELETE;
                        },
                      },
                    });
                  }
                );
              },
            })
          )
        : undefined;

      client.cache.modify({
        fields: {
          orders(existingOrders = {}, { readField }) {
            const newOrderRef = {
              __ref: `Order:${orderActionData.orderId}`,
            };
            const images: readonly string[] | undefined = readField(
              'imageIDs',
              newOrderRef
            );
            if (
              images?.length + addedImages - deletedImages === 0 &&
              existingOrders.orders.some((order: StoreObject | Reference) => {
                const ref = readField('id', order);
                return ref === orderActionData.orderId;
              })
            ) {
              const truncatedArray = truncateArrayAfterNull(
                existingOrders.orders
              );
              const filterOrders = truncatedArray.filter((order) => {
                const ref = readField('id', order as StoreObject | Reference);
                return ref !== orderActionData.orderId;
              });

              filterOrders.splice(-13, 12);

              const setCursorMultimedia = {
                ...cursorMultimedia,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              if (existingOrders?.orders?.length > 0) {
                dispatch(setCursor(tableIds.MULTIMEDIA, setCursorMultimedia));
              }

              return {
                ...existingOrders,
                orders: filterOrders,
                totalCount: existingOrders.totalCount - 1,
                pageInfo: {},
              };
            } else {
              return {
                ...existingOrders,
              };
            }
          },
        },
      });

      await Promise.all(imageArrayWithDeletes);

      await Promise.all([
        editionCapitol || editionSubCapitol || editionElementOrder
          ? client.mutate({
              mutation: UPDATE_ORDER_RELATIONSHIPS,
              variables: {
                input: {
                  id: action.orderIDs[0],
                  capitol: editionCapitol ? editionCapitol : undefined,
                  subCapitol: editionSubCapitol ? editionSubCapitol : undefined,
                  elementOrder: editionElementOrder
                    ? editionElementOrder
                    : undefined,
                },
              },
            })
          : undefined,
        client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            input: {
              id: action.orderIDs[0],
              state:
                parentOrder.state === OrderState.Open
                  ? OrderState.Validated
                  : undefined,
              registerInitDate: registerInitDate ? registerInitDate : undefined,
              registerEndDate: registerEndDate ? registerEndDate : undefined,
            },
          },
        }),
        client.mutate({
          mutation: UPDATE_ACTION,
          variables: {
            input: {
              id: orderActionData.id,
              isValidate: true,
              state:
                parentOrder.state === OrderState.Open
                  ? OrderState.Validated
                  : undefined,
              descriptionValidated: editionDescription
                ? editionDescription
                : undefined,
              laneCutting: editionLaneCutting
                ? editionLaneCutting === 'true'
                : undefined,
              assetSwitch:
                editionAssetSwitch === null
                  ? undefined
                  : editionAssetSwitch === 'true',
              typeOfHour: hoursType ? hoursType : undefined,
              minutesTotals,
              track: track ? track : undefined,
              direction: direction ? direction : action.direction,
              margin: margin ? margin : action.margin,
              lane: lane ? lane : undefined,
              pkInitKm: pkInitRaw ? Number(pkInitRaw[0]) : undefined,
              pkInitMeter: pkInitRaw ? Number(pkInitRaw[1]) : undefined,
              pkEndKm: pkEndRaw ? Number(pkEndRaw[0]) : undefined,
              pkEndMeter: pkEndRaw ? Number(pkEndRaw[1]) : undefined,
              isExternal,
            },
          },
        }),
      ]);

      pkInitRaw ||
      pkEndRaw ||
      track ||
      direction ||
      margin ||
      lane ||
      roadId ||
      concessionId
        ? await client.mutate({
            mutation: UPDATE_POSITION_ACTION,
            variables: {
              input: {
                id: action.geoPositionActionIDs[0],
                pkInitKm: pkInitRaw ? Number(pkInitRaw[0]) : action.pkInit,
                pkInitMeter: pkInitRaw
                  ? Number(pkInitRaw[1])
                  : action.pkInitMeter,
                pkEndKm: pkEndRaw ? Number(pkEndRaw[0]) : action.pkEnd,
                pkEndMeter: pkEndRaw ? Number(pkEndRaw[1]) : action.pkEndMeter,
                track: track ? track : action.track,
                direction: direction ? direction : action.direction,
                margin: margin ? margin : action.margin,
                lane: lane ? lane : action.lane,
                roadId: roadId ? roadId : action.roadIDs[0],
                concessionId: concessionId
                  ? concessionId
                  : action.concessionIDs[0],
              },
            },
          })
        : undefined;
      editionCapitol ||
      editionSubCapitol ||
      editionActivity ||
      editionAsset ||
      concessionId ||
      roadId
        ? await client.mutate({
            mutation: UPDATE_ACTION_RELATIONSHIPS,
            variables: {
              input: {
                id: orderActionData.id,
                userRelationships: action.userIDs,
                vehicleOccupantRelationships: action.vehicleOccupantIDs,
                activityId: editionActivity
                  ? editionActivity
                  : action.activityIDs[0],
                assetId: editionAsset ? editionAsset : undefined,
                capitolId: editionCapitol ? editionCapitol : undefined,
                subCapitolId: editionSubCapitol ? editionSubCapitol : undefined,
                concessionId: concessionId
                  ? concessionId
                  : action.concessionIDs[0],
                roadId: roadId ? roadId : action.roadIDs[0],
              },
            },
          })
        : undefined;

      await Promise.all([
        operatorsToAddIDs.length && !isExternal
          ? client.mutate({
              mutation: CONNECT_MANY_USERS_TO_ACTION,
              variables: {
                input: {
                  actionId: orderActionData.id,
                  userIDs: operatorsToAddIDs,
                },
              },
            })
          : undefined,

        operatorsToDeleteIDs.length && !isExternal
          ? client.mutate({
              mutation: DISCONNECT_MANY_USERS_FROM_ACTION,
              variables: {
                input: {
                  actionId: orderActionData.id,
                  userIDs: operatorsToDeleteIDs,
                },
              },
            })
          : undefined,

        vehiclesToDeleteIDs.length && !isExternal
          ? client.mutate({
              mutation: DELETE_MANY_VEHICLE_OCCUPANTS_ACTION,
              variables: {
                ids: vehiclesToDeleteIDs,
              },
              update(cache, { data: { deleteManyVehicleOccupantsAction } }) {
                deleteManyVehicleOccupantsAction.forEach(
                  (vehicleOccupant: { id: Reference | StoreObject }) => {
                    cache.modify({
                      id: cache.identify(vehicleOccupant.id),
                      fields: {
                        vehicleOccupants(_, { DELETE }) {
                          return DELETE;
                        },
                      },
                    });
                  }
                );
              },
            })
          : undefined,
        newVehiclesData?.length && !isExternal
          ? newVehiclesData.map(
              async (driverAndVehicle: {
                driverId: string;
                vehicleId: string;
              }) => {
                await client.mutate({
                  mutation: NEW_VEHICLE_OCCUPANT_ACTION,
                  variables: {
                    input: {
                      userDriver: driverAndVehicle.driverId,
                      vehicleId: driverAndVehicle.vehicleId,
                      actionId: orderActionData.id,
                    },
                  },
                  update(cache, { data: { createVehicleOccupantAction } }) {
                    cache.modify({
                      fields: {
                        vehicleOccupants(existingVehicleOccupants = []) {
                          const newVehicleOccupantRef = cache.writeFragment({
                            data: createVehicleOccupantAction,
                            fragment: NEW_VEHICLE_OCCUPANT_ACTION,
                          });
                          return [
                            ...existingVehicleOccupants,
                            newVehicleOccupantRef,
                          ];
                        },
                      },
                    });
                  },
                });
                client.query({
                  fetchPolicy: 'network-only',
                  query: GET_VEHICLE_BY_ID,
                  variables: {
                    id: driverAndVehicle.vehicleId,
                  },
                });
              }
            )
          : undefined,

        ...animalsToAdd.map((item) =>
          client.mutate({
            mutation: CREATE_NEW_ANIMAL_UNIT,
            variables: {
              input: {
                unit: Number(item.ut),
                actionId: orderActionData.id,
                animalId: item.id,
                animalStatusId: item.statusId,
              },
            },
            update(cache, { data: { createAnimalUnit } }) {
              cache.modify({
                fields: {
                  animalUnits(existingAnimalUnits = []) {
                    const newAnimalUnitRef = cache.writeFragment({
                      data: createAnimalUnit,
                      fragment: NEW_ANIMAL_UNIT,
                    });
                    return [...existingAnimalUnits, newAnimalUnitRef];
                  },
                },
              });
            },
          })
        ),

        ...(addMaterialsChangesItems ?? []).map(
          (item: { unit: number; id: string }) =>
            client.mutate({
              mutation: CREATE_NEW_MATERIAL_UNIT,
              variables: {
                input: {
                  unit: item.unit,
                  actionId: orderActionData.id,
                  materialId: item.id,
                },
              },
              update(cache, { data: { createMaterialUnit } }) {
                cache.modify({
                  fields: {
                    materialUnits(existingMaterialUnits = []) {
                      const newMaterialUnitRef = cache.writeFragment({
                        data: createMaterialUnit,
                        fragment: NEW_MATERIAL_UNIT,
                      });
                      return [...existingMaterialUnits, newMaterialUnitRef];
                    },
                  },
                });
              },
            })
        ),

        ...(addMachineryChangesItems ?? []).map(
          (item: { unit: number; id: string }) =>
            client.mutate({
              mutation: CREATE_NEW_AUX_MACHINE_UNIT,
              variables: {
                input: {
                  unit: item.unit,
                  actionId: orderActionData.id,
                  auxMachineId: item.id,
                },
              },
              update(cache, { data: { createAuxMachineUnit } }) {
                cache.modify({
                  fields: {
                    auxMachineUnits(existingAuxMachineUnits = []) {
                      const newAuxMachineRef = cache.writeFragment({
                        data: createAuxMachineUnit,
                        fragment: NEW_AUX_MACHINE_UNIT,
                      });
                      return [...existingAuxMachineUnits, newAuxMachineRef];
                    },
                  },
                });
              },
            })
        ),

        updateMachineryChangesItems?.length &&
          client.mutate({
            mutation: UPDATE_MANY_AUX_MACHINE_UNITS,
            variables: {
              input: updateMachineryChangesItemsFiltered,
            },
          }),
        updateMaterialsChangesItems?.length &&
          client.mutate({
            mutation: UPDATE_MANY_MATERIALS_UNITS,
            variables: {
              input: updateMaterialsChangesItemsFiltered,
            },
          }),

        animalsToUpdate?.length &&
          client.mutate({
            mutation: UPDATE_MANY_ANIMAL_UNITS,
            variables: {
              input: animalsToUpdate,
            },
          }),

        deleteMaterialsChangesIds?.length &&
          client.mutate({
            mutation: DELETE_MANY_MATERIALS,
            variables: {
              ids: deleteMaterialsChangesIds,
            },
            update(cache, { data: { deleteManyMaterialUnits } }) {
              deleteManyMaterialUnits.forEach(
                (materialUnit: { id: Reference | StoreObject }) => {
                  cache.modify({
                    id: cache.identify(materialUnit.id),
                    fields: {
                      materialUnits(_, { DELETE }) {
                        return DELETE;
                      },
                    },
                  });
                }
              );
            },
          }),

        deleteMachineryChangesIds?.length &&
          client.mutate({
            mutation: DELETE_MANY_AUX_MACHINE_UNITS,
            variables: {
              ids: deleteMachineryChangesIds,
            },
            update(cache, { data: { deleteManyAuxMachineUnits } }) {
              deleteManyAuxMachineUnits.forEach(
                (auxMachineUnit: { id: Reference | StoreObject }) => {
                  cache.modify({
                    id: cache.identify(auxMachineUnit.id),
                    fields: {
                      auxMachineUnits(_, { DELETE }) {
                        return DELETE;
                      },
                    },
                  });
                }
              );
            },
          }),

        animalsToDelete?.length &&
          client.mutate({
            mutation: DELETE_MANY_ANIMAL_UNITS,
            variables: {
              ids: animalsToDelete,
            },
            update(cache, { data: { deleteManyAnimalUnits } }) {
              deleteManyAnimalUnits.forEach(
                (animalUnit: { id: Reference | StoreObject }) => {
                  cache.modify({
                    id: cache.identify(animalUnit.id),
                    fields: {
                      animalUnits(_, { DELETE }) {
                        return DELETE;
                      },
                    },
                  });
                }
              );
            },
          }),
      ]);
      const {
        data: { action: actionUpdated },
      } = await client.query({
        fetchPolicy: 'network-only',
        query: GET_ACTION_BY_ID,
        variables: { id: orderActionData.id },
      });

      await Promise.all(
        actionUpdated.vehicleOccupantIDs.map((vehicleOccupantId: string) =>
          client.query({
            query: GET_VEHICLE_OCCUPANT_BY_ID,
            variables: {
              id: vehicleOccupantId,
            },
            fetchPolicy: 'network-only',
          })
        )
      );

      await Promise.all([
        actionUpdated.userIDs.map((operatorId: string) =>
          client.query({
            fetchPolicy: 'network-only',
            query: GET_USER_BY_ID,
            variables: {
              input: {
                id: operatorId,
              },
            },
          })
        ),

        client.query({
          fetchPolicy: 'network-only',
          query: GET_ORDER_BY_ID,
          variables: { id: orderActionData.orderId },
        }),
      ]);

      if (editionActivity) {
        await client.query({
          fetchPolicy: 'network-only',
          query: GET_ACTIVITY_BY_ID,
          variables: { id: editionActivity },
        });
      }
      if (editionAsset) {
        await client.query({
          fetchPolicy: 'network-only',
          query: GET_ASSET_BY_ID,
          variables: { id: editionAsset },
        });
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error updating action', err);
  }

  Object.values(formActionValidationNaming).forEach((key) =>
    sessionStorage.removeItem(key)
  );

  sessionStorage.removeItem(formActionNaming.NEW_VALIDATION_ACTION_DATA);
  sessionStorage.removeItem(formActionNaming.NEW_ACTION_DATA);
  sessionStorage.removeItem(externalCompaniesNaming.EXTERNAL_WORKS_TO_ADD);
  sessionStorage.removeItem(externalCompaniesNaming.EXTERNAL_WORKS_TO_DELETE);
  deleteWholeDirectory(OrderType.Action);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store.dispatch(actionTabsReset() as any);

  return redirect(`../../summary/${orderActionData.id}`);
};
