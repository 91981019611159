import { redirect } from 'react-router-dom';

// Constants
import { formOrderNaming } from '../../../../../../components/forms/NewWorkOrderForm/constants';

// GraphQL
import { client } from '../../../../../../gql/client';
import { CREATE_NEW_ORDER } from '../../../../../../gql/mutations/orders';
import { CREATE_NEW_GENERIC_ORDER } from '../../../../../../gql/mutations/generic-order';
import { NEW_GENERIC_ORDER } from '../../../../../../gql/fragments/generic-order';
import { CREATE_NEW_CAMPAIGN } from '../../../../../../gql/mutations/campaign';
import { NEW_CAMPAIGN } from '../../../../../../gql/fragments/campaign';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import { GET_GENERIC_ORDER_BY_ID } from '../../../../../../gql/queries/genericOrders';

// Types
import { OrderState, OrderType } from '../../../../../../types/orders';
import { GenericOrCampaignInput } from './types';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

// REDUX
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { store } from '../../../../../../state/configureStore';
import { getCursor } from '../../../../../../state/selectors/ui/tables';

//Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';

export const saveData = async (id: FormDataEntryValue | null) => {
  let orderInput,
    genericOrCampaignInput: GenericOrCampaignInput = {},
    isCampaignOrGeneric = false,
    campaign;

  const dispatch = store.dispatch;
  const cursorWorkOrderGeneralView = getCursor(tableIds.WORKORDER_GENERAL_VIEW)(
    store.getState()
  );
  const cursorDuplicateGenericOrder = getCursor(
    tableIds.DUPLICATE_GENERIC_ORDER
  )(store.getState());
  const cursorSearchAndCreateWorkOrders = getCursor(
    tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS
  )(store.getState());
  const cursorGenericOrderModal = getCursor(tableIds.GENERIC_ORDER_MODAL)(
    store.getState()
  );
  const creationDate = new Date().toISOString();
  const cursorCampaigns = getCursor(tableIds.CAMPAIGN_MODAL)(store.getState());
  const cursorCampaignModal = getCursor(tableIds.CAMPAIGNS)(store.getState());

  if (id) {
    const {
      data: { order },
    } = await client.query({
      query: GET_ORDER_BY_ID,
      variables: {
        id: id,
      },
    });

    //DOC: data order
    orderInput = {
      type: OrderType.Generic,
      state: OrderState.Open,
      creationDate,
      registerInitDate: order.registerInitDate,
      description: order.description,
      classification: order.classificationIDs[0],
      capitol: order.capitolIDs[0],
      subCapitol: order.subCapitolIDs[0],
    };

    const {
      data: { genericOrder },
    } = await client.query({
      query: GET_GENERIC_ORDER_BY_ID,
      variables: {
        id: order.genericOrdersIDs[0],
      },
    });

    //DOC: input data generic
    genericOrCampaignInput = {
      concessionId: genericOrder.concessionIDs[0],
      roadId: genericOrder.roadIDs[0],
      track: genericOrder.track,
      lane: genericOrder.lane,
      creationDate,
      registerInitDate: order.registerInitDate,
      direction: genericOrder?.direction,
      margin: genericOrder?.margin,
      pkInitKm: genericOrder.pkInitKm,
      pkInitMeter: genericOrder.pkInitMeter,
      pkEndKm: genericOrder.pkEndKm,
      pkEndMeter: genericOrder.pkEndMeter,
    };
  } else {
    const dataFormRaw = sessionStorage.getItem(
      formOrderNaming.NEW_DUPLICATE_WORK_ORDER_FORM
    );
    if (dataFormRaw) {
      const dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));
      isCampaignOrGeneric = dataForm.isCampaignActive === 'true';
      campaign = dataForm.campaign;

      //DOC: data order
      orderInput = {
        type: isCampaignOrGeneric ? OrderType.Campaign : OrderType.Generic,
        state: OrderState.Open,
        creationDate,
        registerInitDate: dataForm.registerInitDate,
        description: dataForm.description,
        classification: dataForm.classification,
        capitol: dataForm.capitol,
        subCapitol: dataForm.subCapitol,
      };

      const pkInitRaw = dataForm.pkInit.replace(/ /g, '').split('+');
      const pkEndRaw = dataForm.pkEnd.replace(/ /g, '').split('+');

      //DOC: input data generic or campaign
      genericOrCampaignInput = {
        concessionId: dataForm.concession,
        roadId: dataForm.road,
        track: dataForm.track,
        lane: dataForm.lane,
        creationDate,
        registerInitDate: dataForm.registerInitDate,
        direction: dataForm?.direction,
        margin: dataForm?.margin,
        pkInitKm: Number(pkInitRaw[0]),
        pkInitMeter: Number(pkInitRaw[1]),
        pkEndKm: Number(pkEndRaw[0]),
        pkEndMeter: Number(pkEndRaw[1]),
      };
    }
  }

  const dataOrder = await client.mutate({
    mutation: CREATE_NEW_ORDER,
    variables: {
      input: orderInput,
    },
  });

  genericOrCampaignInput.orderId = dataOrder.data.createOrder.id;

  if (isCampaignOrGeneric) {
    //DOC: create campaign
    await client.mutate({
      mutation: CREATE_NEW_CAMPAIGN,
      variables: {
        input: {
          name: campaign,
          ...genericOrCampaignInput,
        },
      },
      update(cache, { data: { createCampaign } }) {
        cache.modify({
          fields: {
            campaigns(variables = {}) {
              const newCampaignRef = cache.writeFragment({
                data: createCampaign,
                fragment: NEW_CAMPAIGN,
              });

              const truncatedArray = truncateArrayAfterNull(
                variables.campaigns
              );

              const newCampaignArray = [newCampaignRef, ...truncatedArray];

              newCampaignArray.splice(-2, 1);

              const setCursorDataCampaign = {
                ...cursorCampaigns,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              const setCursorDataCampaignModal = {
                ...cursorCampaignModal,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              if (variables?.campaigns?.length > 0) {
                dispatch(setCursor(tableIds.CAMPAIGNS, setCursorDataCampaign));
                dispatch(
                  setCursor(tableIds.CAMPAIGN_MODAL, setCursorDataCampaignModal)
                );
              }
              return {
                ...variables,
                campaigns: newCampaignArray,
                totalCount: variables.totalCount + 1,
              };
            },
          },
        });
      },
    });
  } else {
    //DOC: create generic order
    await client.mutate({
      mutation: CREATE_NEW_GENERIC_ORDER,
      variables: {
        input: {
          ...genericOrCampaignInput,
          indexExpedition: dataOrder.data.createOrder.indexExpedition,
          indexType: dataOrder.data.createOrder.indexType,
          state: OrderState.Open,
          classification: orderInput?.classification,
          capitolId: orderInput?.capitol,
          subCapitolId: orderInput?.subCapitol,
        },
      },
      update(cache, { data: { createGenericOrder } }) {
        cache.modify({
          fields: {
            genericOrders(variables = {}) {
              const newGenericOrderRef = cache.writeFragment({
                data: createGenericOrder,
                fragment: NEW_GENERIC_ORDER,
              });

              const truncatedArray = truncateArrayAfterNull(
                variables.genericOrders
              );

              const newGenericOrderArray = [
                newGenericOrderRef,
                ...truncatedArray,
              ];

              newGenericOrderArray.splice(-2, 1);

              const setCursorWorkOrderGeneralView = {
                ...cursorWorkOrderGeneralView,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              const setCursorDuplicateGenericOrder = {
                ...cursorDuplicateGenericOrder,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };
              const setCursorSearchAndCreateWorkOrders = {
                ...cursorSearchAndCreateWorkOrders,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };
              const setCursorGenericOrderModal = {
                ...cursorGenericOrderModal,
                take: takeBase,
                cursor: '',
                orderBy: 'desc',
              };

              if (variables?.genericOrders?.length > 0) {
                dispatch(
                  setCursor(
                    tableIds.WORKORDER_GENERAL_VIEW,
                    setCursorWorkOrderGeneralView
                  )
                );
                dispatch(
                  setCursor(
                    tableIds.DUPLICATE_GENERIC_ORDER,
                    setCursorDuplicateGenericOrder
                  )
                );
                dispatch(
                  setCursor(
                    tableIds.SEARCH_AND_CREATE_HISTORICAL_WORKORDERS,
                    setCursorSearchAndCreateWorkOrders
                  )
                );
                dispatch(
                  setCursor(
                    tableIds.GENERIC_ORDER_MODAL,
                    setCursorGenericOrderModal
                  )
                );
              }

              return {
                ...variables,
                genericOrders: newGenericOrderArray,
                totalCount: variables.totalCount + 1,
              };
            },
          },
        });
      },
    });
  }

  // Refetch in parallel
  await client.query({
    fetchPolicy: 'network-only',
    query: GET_ORDER_BY_ID,
    variables: { id: dataOrder.data.createOrder.id },
  });

  // Clean the form local storage as it was just saved
  sessionStorage.removeItem(formOrderNaming.NEW_DUPLICATE_WORK_ORDER_FORM);
  return redirect(`../../${dataOrder?.data.createOrder.id}`);
};
