import { ActionFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../gql/client';
import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
} from '../../../../gql/mutations/orders';
import {
  UPDATE_GENERIC_ORDER,
  UPDATE_GENERIC_ORDER_RELATIONSHIPS,
} from '../../../../gql/mutations/generic-order';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_RELATIONSHIPS,
} from '../../../../gql/mutations/campaign';

// Types
import { OrderType } from '../../../../types/orders';

export const ActionNewWorkOrderDetailed: ActionFunction = async ({
  request,
}) => {
  const formData = await request.formData();
  const pkInit = formData.get('pkInit');
  const pkEnd = formData.get('pkEnd');
  const description = formData.get('description');
  const date = formData.get('date');
  const capitol = formData.get('capitol');
  const subCapitol = formData.get('subCapitol');
  const concession = formData.get('concession');
  const road = formData.get('road');
  const id = formData.get('id');
  const type = formData.get('type');
  const modelTypeId = formData.get('modelTypeId');
  const classification = formData.get('classification');
  const margin = formData.get('margin');
  const direction = formData.get('direction');
  const lane = formData.get('lane');
  const track = formData.get('track');

  await Promise.all([
    date || description
      ? client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            input: {
              id,
              registerInitDate: date ? date : undefined,
              description: description ? description : undefined,
            },
          },
        })
      : undefined,
    capitol || subCapitol || classification
      ? client.mutate({
          mutation: UPDATE_ORDER_RELATIONSHIPS,
          variables: {
            input: {
              id,
              capitol: capitol ? capitol : undefined,
              subCapitol: subCapitol ? subCapitol : undefined,
              classification: classification ? classification : undefined,
            },
          },
        })
      : undefined,
  ]);
  try {
    const pkInitSplit = pkInit?.toString().replace(/ /g, '').split('+');
    const pkEndSplit = pkEnd?.toString().replace(/ /g, '').split('+');
    switch (type) {
      case OrderType.Generic: {
        await Promise.all([
          pkInit || pkEnd || lane || track || margin || direction
            ? client.mutate({
                mutation: UPDATE_GENERIC_ORDER,
                variables: {
                  input: {
                    id: modelTypeId,
                    pkInitKm: pkInit ? Number(pkInitSplit?.[0]) : undefined,
                    pkInitMeter: pkInit ? Number(pkInitSplit?.[1]) : undefined,
                    pkEndKm: pkEnd ? Number(pkEndSplit?.[0]) : undefined,
                    pkEndMeter: pkEnd ? Number(pkEndSplit?.[1]) : undefined,
                    track: track ? track : undefined,
                    margin: margin ? margin : undefined,
                    direction: direction ? direction : undefined,
                    lane: lane ? lane : undefined,
                  },
                },
              })
            : undefined,
          concession || road || capitol || subCapitol || classification
            ? client.mutate({
                mutation: UPDATE_GENERIC_ORDER_RELATIONSHIPS,
                variables: {
                  input: {
                    id: modelTypeId,
                    concessionId: concession ? concession : undefined,
                    roadId: road ? road : undefined,
                    capitolId: capitol ? capitol : undefined,
                    subCapitolId: subCapitol ? subCapitol : undefined,
                    classification: classification ? classification : undefined,
                  },
                },
              })
            : undefined,
        ]);
        break;
      }
      case OrderType.Campaign: {
        await Promise.all([
          pkInit || pkEnd || lane || track || margin || direction
            ? client.mutate({
                mutation: UPDATE_CAMPAIGN,
                variables: {
                  input: {
                    id: modelTypeId,
                    pkInitKm: pkInit ? Number(pkInitSplit?.[0]) : undefined,
                    pkInitMeter: pkInit ? Number(pkInitSplit?.[1]) : undefined,
                    pkEndKm: pkEnd ? Number(pkEndSplit?.[0]) : undefined,
                    pkEndMeter: pkEnd ? Number(pkEndSplit?.[1]) : undefined,
                    track: track ? track : undefined,
                    margin: margin ? margin : undefined,
                    direction: direction ? direction : undefined,
                    lane: lane ? lane : undefined,
                  },
                },
              })
            : undefined,
          concession || road
            ? client.mutate({
                mutation: UPDATE_CAMPAIGN_RELATIONSHIPS,
                variables: {
                  input: {
                    id: modelTypeId,
                    concessionId: concession ? concession : undefined,
                    roadId: road ? road : undefined,
                  },
                },
              })
            : undefined,
        ]);
        break;
      }
    }

    return null;
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work order updated - error updating:', err);
  }
  return null;
};
